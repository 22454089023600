import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import { MdPictureAsPdf } from "react-icons/md"

import SEO from "@components/seo"
import FadeLink from "@components/fade-link"
import Buttons from "@components/buttons"
import Footer from "@components/footer"

import { CgFacebook } from "react-icons/cg"
import PreventionHouseLogo from "@images/svg/prevention-house-logo.svg"
import PreventionHouseText from "@images/svg/prevention-house-text.svg"

const ProgramTemplate = ({ data: { program, wp } }) => {
  const { seo, title, programDetails, terms, slug } = program
  const {
    programDescription,
    sidebarItems,
    mainBody,
    pageImage,
    programBrochure,
    privacyDisclaimer,
  } = programDetails

  return (
    <>
      <main className={`inner-program-wrapper`}>
        <SEO seo={seo} />
        <div className="back-to-bar">
          <div className="container">
            <div className="columns is-flex">
              <div className="column is-6  is-8-mobile">
                <FadeLink
                  className="h6"
                  style={{ margin: 0 }}
                  to={
                    terms?.nodes[0].databaseId === 108
                      ? `/#prevention-house`
                      : `/#programs-services`
                  }
                >
                  <svg
                    viewBox="0 0 25 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    alt="Back Arrow"
                  >
                    <path
                      d="M7.34375 14.844C7.57812 14.6099 7.57812 14.2198 7.34375 13.9077L2.10938 8.13394H24.375C24.6875 8.13394 25 7.82185 25 7.50975C25 7.19766 24.6875 6.88557 24.375 6.88557H2.10938L7.34375 1.11183C7.57812 0.799741 7.57812 0.487647 7.34375 0.175553C7.10938 -0.0585165 6.64062 -0.0585165 6.40625 0.175553C6.40625 0.175553 0.234375 6.96359 0.15625 7.04161C0.15625 7.11964 0 7.27568 0 7.50975C0 7.74382 0.15625 7.89987 0.15625 7.97789C0.234375 8.05592 6.40625 14.844 6.40625 14.844C6.5625 14.922 6.71875 15 6.875 15C7.03125 15 7.1875 14.922 7.34375 14.844Z"
                      fill="#333132"
                    />
                  </svg>
                  {`Back to Programs & Services`}
                </FadeLink>
              </div>

              <div className="column is-6 has-text-right">
                <FadeLink
                  to={
                    terms?.nodes[0].databaseId === 108
                      ? `/#prevention-house`
                      : `/#programs-services`
                  }
                >
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30 0L16 14L2 0L0 2L14 16L0 30L2 32L16 18L30 32L32 30L18 16L32 2L30 0Z"
                      fill="#333132"
                    />
                  </svg>

                  <span className="is-sr-only">
                    {`Back to Programs & Services`}
                  </span>
                </FadeLink>
              </div>
            </div>
          </div>
        </div>
        {terms?.nodes[0].databaseId === 108 ? (
          <div className="prevention-house-bar">
            <div className="container">
              <div className="columns">
                <div className="column is-6 is-flex-tablet">
                  <PreventionHouseLogo style={{ marginBottom: `1.25rem` }} />
                  <PreventionHouseText />
                </div>
                <div className="column is-6 is-flex-tablet has-text-right">
                  <a
                    href={wp.siteOptions.siteOptionsFooter.phFacebookUrl}
                    rel="noreferrer nofollow"
                    target="_blank"
                  >
                    {`Visit us on Facebook`}
                    <CgFacebook style={{ width: `2rem`, height: `2rem` }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <section className="program-introduction">
          {pageImage ? (
            <div
              className="image column is-4"
              style={{
                padding: 0,
              }}
            >
              <img
                src={pageImage.localFile.publicURL}
                alt={pageImage.altText}
              />
            </div>
          ) : null}
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-5">
                <span className="h6">{`Programs & Services`}</span>
                <h1>{title}</h1>
                <hr />
                {programDescription ? (
                  <div className="intro-text">{parse(programDescription)}</div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        <section className="program-main">
          <div className="container">
            <div className="columns">
              <div className="column is-3 is-offset-1">
                <div className="sidebar">
                  {sidebarItems
                    ? sidebarItems.map((sidebarItem, index) => {
                      return (
                        <div
                          key={`sidebarItem_${index}`}
                          className="sidebar-item"
                        >
                          <span className="h3">{sidebarItem.heading}</span>
                          <hr />
                          <span className="text">
                            {parse(sidebarItem.bodyText)}
                          </span>
                        </div>
                      )
                    })
                    : null}
                  {privacyDisclaimer ? (
                    <div className="sidebar-item">
                      <span className="h3">{`Confidentiality`}</span>
                      <hr />
                      <span className="text confidentiality-statement">
                        <svg
                          viewBox="0 0 20 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.83984 4.75132L12.6998 7.24478L12.7198 7.10734C12.7198 5.7919 11.4998 4.73169 9.99984 4.73169L9.83984 4.75132Z"
                            fill="#D34C4C"
                          />
                          <path
                            d="M9.99988 3.14133C12.4999 3.14133 14.5399 4.90834 14.5399 7.08766C14.5399 7.59813 14.4199 8.06934 14.2199 8.52091L16.8799 10.8377C18.2599 9.85599 19.3399 8.56018 19.9999 7.08766C18.4199 3.63217 14.5399 1.17798 9.99988 1.17798C8.71988 1.17798 7.49988 1.37431 6.37988 1.72772L8.33988 3.4162C8.85988 3.25913 9.41988 3.14133 9.99988 3.14133Z"
                            fill="#D34C4C"
                          />
                          <path
                            d="M5.94 5.35995L7.34 6.57723C7.3 6.73429 7.28 6.93063 7.28 7.0877C7.28 8.40314 8.5 9.46335 10 9.46335C10.2 9.46335 10.4 9.42408 10.6 9.40445L12 10.6217C11.4 10.877 10.72 11.034 10 11.034C7.5 11.034 5.46 9.26702 5.46 7.0877C5.46 6.47906 5.64 5.89005 5.94 5.35995ZM0.9 1.00131L2.98 2.80759L3.4 3.16099C1.9 4.18194 0.7 5.53665 0 7.10733C1.58 10.5825 5.46 13.0366 10 13.0366C11.4 13.0366 12.76 12.801 13.98 12.3691L14.36 12.7029L17.02 15L18.18 13.9987L2.06 0L0.9 1.00131Z"
                            fill="#D34C4C"
                          />
                        </svg>
                        <span>
                          {
                            "All sessions with clients are kept strictly confidential."
                          }
                        </span>
                      </span>
                    </div>
                  ) : null}
                </div>

                {programBrochure ? (
                  <a
                    href={programBrochure.localFile.publicURL}
                    target="_blank"
                    className="button"
                    rel="noreferrer"
                  >
                    <MdPictureAsPdf />
                    {` View Program Brochure`}
                  </a>
                ) : null}
              </div>
              <div className="column is-6 is-offset-1">
                {mainBody
                  ? mainBody.map((mainBodyItem, index) => {
                    return (
                      <div
                        key={`mainBodyItem_${index}`}
                        className="body-item"
                      >
                        <span className="h3">{mainBodyItem.heading}</span>
                        <hr />
                        <span className="text">
                          {parse(mainBodyItem.bodyText)}
                        </span>
                        {mainBodyItem.links ? (
                          <Buttons links={mainBodyItem.links} />
                        ) : null}
                      </div>
                    )
                  })
                  : null}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer
        isProgramPage={true}
        programSlug={slug}
        programType={terms?.nodes[0].slug}
        hasPreventionHouseCallout={
          terms?.nodes[0].databaseId === 108 ? true : false
        }
      />
    </>
  )
}

export default ProgramTemplate

export const programQuery = graphql`
  query ProgramById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    wp {
      siteOptions {
        siteOptionsFooter {
          phFacebookUrl
        }
      }
    }
    # selecting the current post by id
    program: wpProgram(id: { eq: $id }) {
      id
      title
      slug
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        readingTime
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphDescription
        opengraphAuthor
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      programDetails {
        ageGroup {
          ageGroup
        }
        programDescription
        privacyDisclaimer
        sidebarItems {
          bodyText
          heading
        }
        mainBody {
          heading
          bodyText
          links {
            anchorId
            customUrl
            externalUrl
            forceDownload
            linkText
            linkType
            pageLink {
              ... on WpPage {
                id
                uri
              }
              ... on WpProgram {
                id
                uri
              }
              ... on WpPost {
                id
                uri
              }
            }
            file {
              localFile {
                publicURL
              }
            }
          }
        }
        locations {
          name
        }
        pageImage {
          localFile {
            publicURL
          }
          altText
        }
        programBrochure {
          localFile {
            publicURL
          }
        }
      }
      terms {
        nodes {
          ... on WpProgramType {
            id
            slug
            name
            databaseId
          }
        }
      }
    }
  }
`
